$base-yellow: #d1b056;
$background-color: #26252a;

body {
  background: #26252a;
}

.limoking-content {
  position: relative;
}

#logo {
  margin-top: 15px;
}

h1 {
  color: $base-yellow;
  margin-top: 50px;
  text-transform: capitalize;
}

.dtp > .dtp-content {
  max-height: 530px;
}

.btn.btn-raised {
  border-radius: 0;
  color: $background-color;
}

.help-block {
  color: rgba(#fff, 0.5);
}

.togglebutton label {
  color: #fff;
}

.form-control {
  color: $base-yellow;
}

.btn.btn-raised.btn-primary {
  color: #fff
}

.btn.btn-raised.btn-warning {
  color: $background-color;
}

.input-group-btn .btn.btn-fab {
  background-color: $base-yellow;
  color: #fff !important;
}

.footer {
  //color: rgba(#fff, 0.5);
}

.why-us-item {
  margin-bottom: 15px;
  font-family: "Montserrat";
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
}


.custom-icon {
  background: #E9AE48;
  //background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  border: 4px solid #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.46);
  color: #fff;
  display: table-cell;
  font-size: 20px;
  height: 50px;
  padding: 30px;
  text-align: center;
  transition: .5s;
  vertical-align: middle;
  width: 50px;
}
.custom-icon:hover {
  background: rgba(0, 0, 0, 0.6);
}
.icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
}

.input-group-addon {
  border: 0px;
  background-color: #d1b056;
  color: white;

  .btn {
    background: transparent;

    &:focus, &:active:focus, &.active:focus, &.focus, &:active.focus, &.active.focus {
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
    &:active {
      box-shadow: none;
    }
  }
}

.wpcf7 input {
  height: 50px;
  box-shadow: none;
  border-radius: 0;
}

.wpcf7 span.wpcf7-form-control-wrap {
  padding-right: 0px;
}

#content-section-3 {

  padding-top: 70px;
  padding-bottom: 20px;

  .col-md-4 {
    padding: 10px 0;
  }

  .column-service-image {
    max-width: 50px;
    float: left;
    margin-right: 20px;
  }

  .column-service-content-wrapper {
    margin-left: 70px;
  }

  .column-service-title {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
    //min-height: 40px;
  }

  .column-service-content {


    ul {
      //margin-left: 20px;

      li {
        line-height: 1.7;
      }
    }
  }
}

.master-slider img, .ms-slide-bgvideocont video {
  margin-top: 0!important;
}

.limoking-color-wrapper, .limoking-parallax-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
}

.copyright-wrapper {
  border-top-width: 0px;
}


#references {
  background-color: #252525;

  #slick-logos {
    font-family: 'Anton', sans-serif;
    font-size: 32px;
    text-align: center;

    a {
      color: #a8a8ab;
      text-shadow: 0px 0px 20px #000000;
    }

    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.floating-button {
  position: fixed;
  z-index: 999;
  background: $base-yellow;
  text-align: center;
  padding: 10px 40px;

  &.login {
    top: 240px;
    right: 0;
  }

  &.book-online {
    top: 290px;
    right: 0;
  }

  a {
    color: #fff;
  }
}


$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

